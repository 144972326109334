<template>
  <div class="reimburseView">
    <detail-top 
      :tabsList="tabsList" 
      tabsType="card"
      :createTime="detailInfo.createTime"
      headerTitle="查看销售合同详情"
      :title="`${detailInfo.createUser || ''}提交的销售合同审批申请`"
    >
      <div v-if="detailInfo.contractAgreementVOs && detailInfo.contractAgreementVOs.length !== 0" 
        class="cut" @click="handleCutPage">
        <van-icon name="exchange" />
        <span>查看协议</span>
      </div>
      <template  v-slot:van-top>
        <van-tabs v-model="active" @click="handleTabs" :shrink="true">
          <van-tab title="合同" name="1"></van-tab>
          <!-- <van-tab title="协议" name="2"></van-tab> -->
          <!-- <van-tab title="归档" name="3"></van-tab> -->
          <van-tab title="评估单" name="evaluation" :disabled="!evaluationInfo"></van-tab>
          <van-tab title="报价单" name="quotation" :disabled="!evaluationInfo"></van-tab>
          <van-tab title="交接单" name="receipt" :disabled="!evaluationInfo"></van-tab>
        </van-tabs>
      </template>
    </detail-top>
    <div :class="[auditAuthority === 1 ? 'on-approval' : '', 'content-wrap']">
      <div v-if="active == 1">
        <my-card name="1" title="基础信息">
          <div class="basics-wrap">
            <my-cell title="所属部门" :value="detailInfo.departmentName" />
            <my-cell title="所属部门对接人" :value="detailInfo.departmentPersonName" />
            <my-cell title="所属客户" :value="detailInfo.customerName" />
            <my-cell title="所属产品" :value="detailInfo.productName" />
            <my-cell title="所属项目" :value="detailInfo.projectName" />
            <my-cell title="交付部门" :value="detailInfo.deliveryDepartmentName" />
            <my-cell title="交付部门对接人" :value="deliveryName" />
            <img class="status-img" :src="statusImg" />
          </div>
        </my-card>

        <my-card name="2" title="合同信息">
          <my-cell title="签订公司" :value="detailInfo.serviceCompanyName" />
          <my-cell title="合同名称" :value="detailInfo.contractName" />
          <my-cell title="合同编号" :value="detailInfo.contractNo" />
          <my-cell title="合同起止日期" :value="`${detailInfo.contractStart}~${detailInfo.contractEnd}`" />
          <my-cell title="合同签约日期" :value="detailInfo.signDate	" />
          <my-cell title="是否换签合同" :value="detailInfo.signChange ? '是' : '否'" />
          <my-cell v-if="detailInfo.signChange" title="换签合同" :value="detailInfo.saleContractName" />
          <my-cell title="财务分类" :value="detailInfo.accountType" />
          <my-cell title="创建人" :value="detailInfo.createUser" />
          <my-cell title="创建时间" :value="detailInfo.createTime" />
          <my-cell title="是否虚拟合同" :value="detailInfo.virtuals ? '是' : '否'" />
          <my-cell title="是否渠道资源" :value="detailInfo.channelType ? '是' : '否'" />
          <my-cell v-if="detailInfo.channelType" title="关联渠道合同">
            <div class="contract-list" v-for="item in detailInfo.contractConcerns" :key="item.concernId">
              <span>{{ item.contractName }}</span>
            </div>  
          </my-cell>
          <my-cell title="是否自动续签" :value="detailInfo.signUp ? '是' : '否'" />
          <my-cell title="服务费率" :value="`${detailInfo.serviceRate || 0}%`" />
          <my-cell title="合同签约价" :value="`${detailInfo.settleRate}${detailInfo.settleType}`" />
          <my-cell title="附件" />
          <my-files-view :fileList="detailInfo.contractFiles" />
          <my-cell title="备注" :value="detailInfo.remark" />
        </my-card>

        <my-card name="3" title="开票信息">
          <template v-slot:right>
            共计{{ detailsTotal }}条
          </template>
          <div class="details-list-wrap">
            <div v-for="item in detailInfo.contractInvoices" :key="item.invTaxId" class="detail-item">
              <div class="main-two">
                <div class="detail-cell">
                  <div class="title">开票税率</div>
                  <div class="value">{{ item.taxRate }}%</div>
                </div>
                <div class="detail-cell">
                  <div class="title">类目</div>
                  <div class="value">{{ item.invTaxName }}</div>
                </div>
              </div>
            </div>
            <div v-if="isUnfold" class="unfold-wrap" @click="handleUnfold">
              <div class="unfold">展开</div>
              <van-icon name="arrow-down" />
            </div>
          </div>
        </my-card>
      </div>

      <div v-else-if="active == 'evaluation'">
        <my-card name="1" title="准入评估">
          <div class="basics-wrap">
            <my-cell title="客户全称" :value="customerInfo.customerName" />
            <my-cell title="是否敏感客户" :value="evaluationInfo.sensitiveCustomer == 1 ? '敏感' : '非敏感'" />
            <my-cell title="签约主体" :value="evaluationInfo.companyName" />
            <my-cell title="营业执照" />
            <my-files-view :fileList="[{fileUrlMark: customerInfo.fileUrl, fileName: '营业执照.png'}]" />
            <my-cell title="成立时间" :value="customerInfo.setUpTime" />
            <my-cell title="法人姓名" :value="customerInfo.legalName" />
            <my-cell title="注册地址" :value="customerInfo.contactAddress" />
            <my-cell title="统一信用代码" :value="customerInfo.dutyParagraph" />
            <my-cell title="主营业务" :value="customerInfo.businessScope" />
            <my-cell title="客户所属行业" :value="customerInfo.industryName" />
            <my-cell title="业务场景描述" :value="evaluationInfo.businessScenarioDesp" />
            <my-cell title="其他产品线合作状态" :value="evaluationInfo.cooperation ==  1 ? '已合作' : '未合作'" />
            <my-cell title="业务关联的其他客户" :value="evaluationInfo.custList.map(item => `${item.custName}`).join('、')" />
            <my-cell title="业务关联客服" :value="serviceNameList" />
            <my-cell title="其他评估资料" />
            <my-files-view :fileList="evaluationInfo.otherFileList" />
            <my-cell title="税单或社保证明" />
            <my-files-view :fileList="evaluationInfo.certificateFileList" />
            <img class="status-img" :src="statusImg" />
          </div>
        </my-card>
        <my-card name="2" title="结算信息">
          <div class="basics-wrap">
            <my-cell title="发票开具方式" :value="evaluationInfo.invoicingMode == 1 ? '先款后票' : '先票后款'" />
            <my-cell title="发票类型" :value="evaluationInfo.invoiceType == 1 ? '全额普票' : '全额专票'" />
            <my-cell title="开票类目" :value="evaluationInfo.invoiceCategoryList.map(item => `${item.categoryName}(${item.taxRate}%)`).join('、')" />
          </div>
        </my-card>
        <my-card name="3" title="分包商信息">
          <div class="basics-wrap">
            <my-cell title="分包商需求人数" :value="
              evaluationInfo.invoicingMode == 0 ? '100人以下' : 
              evaluationInfo.invoicingMode == 1 ? '100~500人' : 
              evaluationInfo.invoicingMode == 2 ? '500~1000人' : 
              evaluationInfo.invoicingMode == 3 ? '1000~5000人' : 
              evaluationInfo.invoicingMode == 4 ? '5000人以上' : ''" 
            />
            <my-cell title="分包商月平均金流" :value="
              evaluationInfo.subcontAvgCashFlow == 0 ? '1.5万以下' : 
              evaluationInfo.subcontAvgCashFlow == 1 ? '3万以下' : 
              evaluationInfo.subcontAvgCashFlow == 2 ? '3~8万以下' : 
              evaluationInfo.subcontAvgCashFlow == 3 ? '8万以上' : ''" 
            />
            <my-cell title="月总金流预估" :value="
              evaluationInfo.subcontTotalCashFlow == 0 ? '100万以下' : 
              evaluationInfo.subcontTotalCashFlow == 1 ? '100-500万' : 
              evaluationInfo.subcontTotalCashFlow == 2 ? '500-1000万' : 
              evaluationInfo.subcontTotalCashFlow == 3 ? '1000-1500万' : 
              evaluationInfo.subcontTotalCashFlow == 4 ? '1500-2000万' : 
              evaluationInfo.subcontTotalCashFlow == 5 ? '2000-2500万' : 
              evaluationInfo.subcontTotalCashFlow == 6 ? '2500-3000万' : ''" 
            />
            <my-cell title="3万以上人数占比" :value="`${evaluationInfo.thirtyThousandPercent}%`" />
            <my-cell title="分包商签约方式" :value="
              evaluationInfo.subcontSigningMethod == 0 ? '标准签' :
              evaluationInfo.subcontSigningMethod == 1 ? '静默签' : ''" 
            />
            <my-cell title="是否需要承诺书" :value="
              evaluationInfo.needUndertaking == 1 ? '需要' :
              evaluationInfo.needUndertaking == 0 ? '不需要' : ''" 
            />
          </div>
        </my-card>
        <my-card name="5" title="场景信息">
          <div class="basics-wrap">
            <my-cell title="交付标准" :value="evaluationInfo.deliveryStandard" />
            <my-cell title="具体交付物名称" :value="evaluationInfo.deliverablesName" />
            <my-cell title="计算方式" :value="evaluationInfo.billingMethod" />
            <my-cell title="计算单位" :value="evaluationInfo.measurementUnit" />
            <my-cell title="项目单价/提成比例/结算工时" :value="evaluationInfo.concreteNumber" />
          </div>
        </my-card>
      </div>

      <div v-else-if="active == 'quotation'">
        <my-card name="1" title="报价单信息">
          <div class="basics-wrap">
            <my-cell title="报价单详情" />
            <my-files-view :fileList="detailInfo.contractQuotationVO.quotationFiles" />
          </div>
        </my-card>
      </div>

      <div v-else-if="active == 'receipt'">
        <my-card name="1" title="渠道信息">
          <div class="basics-wrap">
            <my-cell title="是否有渠道" :value="deliveryReceipt.isChannel == 1 ? '有' : '没有'" />
            <my-cell title="渠道名称" :value="channelNames.map(item => `${item}`).join('、')" />
            <my-cell title="渠道合同" :value="contractNames.map(item => `${item}`).join('、')" />
          </div>
        </my-card>
        <my-card name="2" title="服务信息">
          <div class="basics-wrap">
            <my-cell title="系统发放模式" :value="
              deliveryReceipt.distributionMode == 1 ? '代发' : 
              deliveryReceipt.distributionMode == 2 ? '自发' : ''" 
            />
            <my-cell title="发放通道" :value="
              deliveryReceipt.distributionChannel == 1 ? '支付宝' : 
              deliveryReceipt.distributionChannel == 2 ? '银行' : ''" 
            />
            <my-cell title="是否有系统对接" :value="
              deliveryReceipt.systemDocking == 0 ? '没有' : 
              deliveryReceipt.systemDocking == 1 ? '有' : ''" 
            />
            <my-cell title="开票模式" :value="
              deliveryReceipt.invoicingMode == 1 ? '任务结算金流' : 
              deliveryReceipt.invoicingMode == 2 ? '充值金流' : ''" 
            />
            <my-cell title="客户联系人" :value="deliveryReceipt.contacter" />
            <my-cell title="客户联系人联系方式" :value="deliveryReceipt.contactInfo" />
            <my-cell title="系统账户（手机号）" :value="deliveryReceipt.systemAccount" />
            <my-cell title="下发验证码手机号" :value="deliveryReceipt.sendVerification" />
            <my-cell title="其他服务内容、费用及要求" :value="deliveryReceipt.remark" />
          </div>
        </my-card>
      </div>

      <!-- 标准合同module是3，非标准是6 -->
      <approval-process name="4" v-if="reimId && (detailInfo.standard === 0 || detailInfo.standard === 1)" 
        :billId="reimId" :module="detailInfo.standard === 1 ? 3 : 6" />
    </div>
    <detail-approval-btn v-if="auditAuthority === 1" :billNo="detailInfo.contractNo" />
  </div>
</template>

<script>
import Vue from 'vue';
import { NavBar, Icon, Image as VanImage, } from 'vant';
import { contractView, reqCustomerInfo, reqRelatedCustService, reqChannelInfo, } from '@/api/reimburse'
import passImg from '@/assets/approvalPassImg.png'
import rejectImg from '@/assets/approvalRejectImg.png'

Vue.use(NavBar).use(Icon).use(VanImage);

export default {
  name: "reimburseView",
  data() {
    return {
      reimId: '', // id
      tabsList: [],
      contractList: [
        {
          title: '基础信息',
          key: 1,
        },
        {
          title: '合同信息',
          key: 2,
        },
        {
          title: '开票信息',
          key: 3,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      evaluationList: [
        {
          title: '准入评估',
          key: 1,
        },
        {
          title: '结算',
          key: 2,
        },
        {
          title: '分包商',
          key: 3,
        },
        {
          title: '场景',
          key: 5,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      quotationList: [
        {
          title: '报价单信息',
          key: 1,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      receiptList: [
        {
          title: '渠道信息',
          key: 1,
        },
        {
          title: '服务信息',
          key: 2,
        },
        {
          title: '审批流程',
          key: 4,
        },
      ],
      detailInfo: {},
      detailsList: [],
      isUnfold: false,
      detailsTotal: 0,
      statusImg: '',
      auditAuthority: 0,
      deliveryName: '',
      active: '',
      evaluationInfo: '',
      customerInfo: '',
      serviceNameList: [],
      deliveryReceipt: '',
      channelNames: [],
      contractNames: [],
    };
  },
  components: {
    ApprovalProcess: () => import('@/components/business/approvalProcess/index.vue'),
    DetailTop: () => import('@/components/business/detailTop/index.vue'),
    MyCard: () => import('@/components/basics/MyCard.vue'),
    MyCell: () => import('@/components/basics/MyCell.vue'),
    MyFilesView: () => import('@/components/basics/MyFilesView.vue'),
    // DetailItem: () => import('./components/DetailItem.vue'),
    DetailApprovalBtn: () => import('@/components/business/detailApprovalBtn/index.vue'),
  },
  created(){
    this.tabsList = this.contractList;
  },
  mounted() {
    // 查看是否有内容
    this.reimId = this.$route.query.reimId;
    this.totalAmount = this.$route.query.totalAmount;
    this.status = this.$route.query.status;
    this.auditAuthority = this.$route.query.auditAuthority;
    this.agreementAuditAuthority = this.$route.query.agreementAuditAuthority;
    if (this.reimId) {
      this.getDetailInfo()
    } else {
      console.log("打开错误");
    }
  },
  methods: {
    // 获取渠道名称和合同名称
    getChannelName(contractIds) {
      reqChannelInfo({ contractIds }).then(res => {
        if (res.success) {
          this.channelNames = res.resultData.channelNames;
          this.contractNames = res.resultData.contractNames;
        }
      })
    },
    // 获取业务关联客服
    getRelatedCustService(custIdArrayStr) {
      reqRelatedCustService({custIdArrayStr: custIdArrayStr.toString()}).then(res => {
        if(res.success){
          this.serviceNameList = res.resultData
        }
      })
    },
    // 获取当前客户信息
    getCustomerInfo(customerId, productId) {
      reqCustomerInfo({
        customerId,
        productId,
      }).then(res => {
        if(res.success){
          this.customerInfo = res.resultData;
        }
      })
    },
    getDetailInfo() {
      contractView({contractId: this.reimId}).then(res => {
        let obj = res.resultData;
        this.detailInfo = obj;
        let list = obj.contractInvoices;
        this.detailsTotal = list.length
        if(list.length > 3){
          this.detailsList = list.slice(0, 3);
          this.isUnfold = true;
        } else {
          this.detailsList = list;
        }
        this.getCustomerInfo(obj.customerId, obj.productId)
        this.evaluationInfo = obj?.contractEvalDetailVO
        // 处理业务关联其他客户
        let custList = this.evaluationInfo?.custList?.map(item => item.custId)
        custList?.length > 0 && this.getRelatedCustService(custList)
        this.deliveryReceipt = obj?.contractDeliveryReceiptVO
        let ids = obj.contractConcerns.map(
            (item) => item.contractId
          );
        ids?.length > 0 && this.getChannelName(ids)
        this.getDeliverys()
        if(obj.contractStatus === 5){ // 驳回
          this.statusImg = rejectImg;
        } else if(obj.contractStatus === 2){ // 通过
          this.statusImg = passImg;
        }
      })
    },
    handleUnfold(){
      this.detailsList = this.detailInfo.details;
      this.isUnfold = false;
    },
    handleCutPage(){
      console.log(this.detailInfo,'合同中')
      this.$router.replace({
        name: 'agreementView',
        query: {
          reimId: this.reimId,
          status: this.status,
          auditAuthority: this.auditAuthority,
          agreementAuditAuthority: this.agreementAuditAuthority,
        }
      })
    },
    getDeliverys(){
      let name = ''
      this.detailInfo.contractDeliverys.filter(item => {
        name += `${item.personName},`
      })
      this.deliveryName = name;
    },
    handleTabs(e){
      if(e == '1'){
        this.tabsList = this.contractList;
      } else if(e == 'evaluation'){
        this.tabsList = this.evaluationList;
      } else if(e == 'quotation'){
        this.tabsList = this.quotationList;
      } else if(e == 'receipt'){
        this.tabsList = this.receiptList;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.reimburseView{
  .content-wrap{
    overflow: auto;
    height: calc(100vh - 211px);
    .basics-wrap{
      position: relative;
      .status-img{
        width: 50%;
        position: absolute;
        z-index: 99;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.3;
      }
    }
    .details-list-wrap{
      .detail-item{
        background-color: #f7f8fcc9;
        border-radius: 6px;
        padding: 4px 10px;
        margin: 10px 0;
        @mixin main{
          display: flex;
          justify-content: space-between;
          margin: 10px 0;
          .detail-cell{
            flex: 1;
            display: flex;
            flex-direction: column;
            &:nth-child(1){
              text-align: left;
            }
            .title{
              font-size: 12px;
              color: $gray;
            }
            .value{
              margin: 4px 0;
              font-size: 14px;
              font-weight: 600;
              color: $font;
            }
          }
        }
        >.main-two{
          @include main;
          .detail-cell{
            &:nth-child(2){
              text-align: right;
            }
          }
        }
      }
      .unfold-wrap{
        color: $gray;
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        .unfold{
          margin-right: 4px;
        }
      }
    }
    >.my-card{
      >.contract-list{
        margin: 10px 0;
        text-align: right;
      }
    }
  }
  >.on-approval{
    height: calc(100vh - 252px);
  }
}

</style>